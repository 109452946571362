<template>
  <div>
    <div class="d-flex my-3 justify-content-start">
      <button class="btn btn-success btn-md" @click="add()">
        <i class="flaticon-add"></i> Add Design Reference
      </button>
    </div>

    <div class="table-responsive mt-5">
      <table
        class="table table-head-custom table-vertical-center table-head-bg table-borderless"
      >
        <thead>
          <tr class="text-left">
            <th class="pl-7" style="min-width: 250px">
              <span class="text-dark-75">Description</span>
            </th>
            <th style="min-width: 100px">Design Reference Type</th>
            <th style="min-width: 100px">Action</th>
          </tr>
        </thead>

        <tbody>
          <template v-for="(designReference, i) in designReferences">
            <tr v-bind:key="i">
              <td class="pl-0 ">
                <template v-if="currentIndex !== i">
                  <span
                    class="ml-5 text-dark-75 font-weight-bolder d-block font-size-lg"
                  >
                    {{ designReference.description }}
                  </span>
                </template>

                <template v-if="currentIndex === i">
                  <b-form-input
                    id="input-large"
                    size="lg"
                    v-model="designReference.description"
                  ></b-form-input>
                </template>
              </td>

              <td class="pl-0 ">
                <template v-if="currentIndex !== i">
                  <span
                    class="ml-5 text-dark-75 font-weight-bolder d-block font-size-lg"
                  >
                    <span v-if="designReference.design_reference_type != null">
                      {{ designReference.design_reference_type.name }}
                    </span>
                  </span>
                </template>

                <template v-if="currentIndex === i">
                  <b-form-select
                    v-if="designReference.design_reference_type != null"
                    v-model="designReference.design_reference_type.code"
                    :options="formattedDesignReferenceTypeOptions"
                    size="lg"
                    @change="setCurrentName($event, i)"
                  ></b-form-select>
                  <b-form-select
                    v-else
                    :options="formattedDesignReferenceTypeOptions"
                    size="lg"
                    @change="setCurrentName($event, i)"
                  ></b-form-select>
                </template>
              </td>

              <td class="pl-0 ">
                <a
                  class="btn btn-light-success font-weight-bolder font-size-sm"
                  @click="currentIndex = i"
                  v-if="currentIndex !== i"
                >
                  <i class="flaticon-edit"></i>
                </a>

                <a
                  class="btn btn-light-success font-weight-bolder font-size-sm"
                  @click="currentIndex = null"
                  v-if="currentIndex === i"
                >
                  <i class="flaticon2-cross"></i>
                </a>

                <a
                  class="btn btn-light-danger font-weight-bolder font-size-sm ml-2"
                  @click="remove(i)"
                >
                  <i class="flaticon2-trash"></i>
                </a>
              </td>
            </tr>
          </template>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { arrayMoreThanOne } from "../../../../../core/helper/array-validation.helper";
import { GET_DESIGN_REFERENCE_TYPE } from "../../../../../core/services/store/design-reference-type.module";
import { deleteModal } from "../../../../../core/helper/toast.helper";

export default {
  name: "DesignReference",
  props: {
    designReferences: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      designReference: {
        id: null,
        station: null
      },
      currentIndex: null,
      options: {
        designReferences: [
          {
            text: "Dokumen & Gambar Situasi",
            value: 1
          },
          {
            text: "Regulasi Pemerintah",
            value: 2
          },
          {
            text: "Standar Design Produk",
            value: 3
          },
          {
            text: "Spesifikasi / Permintaan Khusus",
            value: 4
          }
        ]
      }
    };
  },
  computed: {
    ...mapState({
      designReferenceType: state =>
        state.designReferenceType.designReferenceTypes?.data,
      scopeOfDesign: state => state.scopeOfDesign.scopeOfDesigns?.data
    }),

    formattedDesignReferenceTypeOptions() {
      let designReferenceType = [];

      if (arrayMoreThanOne(this.designReferenceType)) {
        designReferenceType = this.designReferenceType.map(scope => {
          return {
            value: scope.code,
            text: scope.name
          };
        });
      }

      return [{ value: null, text: "Choose Design Reference Type" }, ...designReferenceType];
    }
  },
  methods: {
    setCurrentName(value, index) {
      var getReference = this.designReferences[index].design_reference_type;
      if (getReference == null) {
        var getOptions = this.formattedDesignReferenceTypeOptions.find(
          x => x.value == value
        );
        getReference = {
          name: getOptions.text,
          code: getOptions.value
        };
        this.designReferences[index].design_reference_type = getReference;
      } else {
        const currentOption = this.formattedDesignReferenceTypeOptions.find(
          data =>
            data.value ===
            this.designReferences[index].design_reference_type.code
        );
        this.designReferences[index].design_reference_type.name =
          currentOption?.text;
      }
      
      this.$emit("setDesignReferences", {
        design_reference: this.designReferences
      });
    },

    displayName(value) {
      return this.options.designReferences.find(
        design => design.value === value
      )?.text;
    },

    getDesignReferenceType() {
      if (!arrayMoreThanOne(this.designReferenceType)) {
        this.$store.dispatch(GET_DESIGN_REFERENCE_TYPE, {});
      }
    },

    remove(index) {
      const deleteFunction = () => {
        const designReferences = this.designReferences;

        designReferences.splice(index, 1);

        this.$emit("setDesignReferences", {
          design_reference: designReferences
        });
      };

      deleteModal(deleteFunction);
    },

    add() {
      const designReferences = this.designReferences;

      designReferences.push({
        id: null,
        description: null,
        design_reference_type: {
          code: null,
          name: null
        }
      });

      this.$emit("setDesignReferences", { design_reference: designReferences });
    },

    update(index, designReference) {
      const designReferences = this.designReferences;

      designReferences[index] = designReference;

      this.$emit("setDesignReferences", { design_reference: designReferences });
    }
  },
  mounted() {
    this.getDesignReferenceType();
  }
};
</script>
