<template>
  <div>
    <div class="row">
      <div class="col-md-6 py-2 col-sm-12">
        <label for="input-large">DMP Number</label>

        <b-form-input
          id="input-large"
          size="lg"
          v-model="form.number"
          @blur="update"
        ></b-form-input>
      </div>

      <div class="col-md-6 py-2 col-sm-12">
        <label for="input-large">EVP Engineering</label>

        <b-form-input
          id="input-large"
          @blur="update"
          size="lg"
          v-model="form.evp_name"
        ></b-form-input>
      </div>

      <div class="col-md-6 py-2 col-sm-12">
        <label for="input-large">Revision</label>

        <b-form-input
          id="input-large"
          @blur="update"
          size="lg"
          v-model="form.revision"
        ></b-form-input>
      </div>

      <div class="col-md-6 py-2 col-sm-12">
        <label for="input-large">EVP Project</label>

        <b-form-input
          id="input-large"
          @blur="update"
          size="lg"
          v-model="form.vp_name"
        ></b-form-input>
      </div>

      <div class="col-md-6 py-2 col-sm-12">
        <label for="input-large">Revision Date</label>
        <b-input-group>
          <input
            class="form-control"
            type="text"
            v-model="form.revision_date"
            id="example-date-input"
            placeholder="YYYY-MM-DD"
          />
          <b-input-group-append>
            <b-form-datepicker
              button-only
              right
              reset-button
              reset-value=""
              :no-flip="true"
              v-model="form.revision_date"
            ></b-form-datepicker>
          </b-input-group-append>
        </b-input-group>
      </div>

      <div class="col-md-6 py-2 col-sm-12">
        <label for="input-large">Design Document Flow</label>
        0
        <b-form-group>
          <b-form-input
            id="input-large"
            size="lg"
            v-model="form.flow_doc_design_flow"
            disabled
          ></b-form-input>

          <div class="mt-2">
            <b-form-file
              id="file-large"
              size="lg"
              v-model="file"
              @change="uploadDoc"
            ></b-form-file>
          </div>
        </b-form-group>
      </div>
    </div>
  </div>
</template>

<script>
import ApiService from "@/core/services/api.service";
import { showToast } from "@/core/helper/toast.helper";

export default {
  name: "DmpInfo",
  props: {
    dmpInfo: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      form: {
        number: null,
        revision: null,
        evp_name: null,
        vp_name: null,
        revision_date: null,
        document: null,
        flow_doc_design_flow: null,
      },
      file: null,
    };
  },
  mounted() {
    this.form = this.dmpInfo;
  },
  watch: {
    dmpInfo(data) {
      Object.assign(this.form, data);
    },
  },
  methods: {
    update() {
      this.$emit("setDmpInfo", {
        design_management_plan: this.form,
      });
    },

    uploadDoc(event) {
      const file = event.target?.files[0];

      const formData = new FormData();

      formData.append("design_doc_flow", file);

      formData.append("project_code", this.$route.params?.code);

      ApiService.setHeader();

      ApiService.post("project/design/management/plan/flow", formData)
        .then((response) => {
          this.form.flow_doc_design_flow = response?.data?.data?.file;
        })
        .catch(() => {
          showToast("Error", "Failed upload file", "danger");
        });
    },
  },
};
</script>
