<template>
  <div>
    <template v-if="!loading">
      <div class="card card-custom p-5 gutter-b">
        <div class="card-body p-0">
          <div class="row">
            <div class="col-md-6 py-2 col-sm-12">
              <label for="input-large">Project Code</label>

              <b-form-input
                id="input-large"
                size="lg"
                disabled
                placeholder="Project Code"
                v-model="form.code"
              ></b-form-input>
            </div>

            <div class="col-md-6 py-2 col-sm-12">
              <label for="input-large">Project Owner</label>

              <b-form-input
                id="input-large"
                size="lg"
                disabled
                placeholder="Owner"
                v-model="form.owner"
              ></b-form-input>
            </div>

            <div class="col-md-6 py-2 col-sm-12">
              <label for="input-large">Project Name</label>

              <b-form-input
                id="input-large"
                size="lg"
                disabled
                placeholder="Project Name"
                v-model="form.name"
              ></b-form-input>
            </div>

            <div class="col-md-6 py-2 col-sm-12">
              <label for="input-large">Contract Number</label>

              <b-form-input
                id="input-large"
                size="lg"
                disabled
                placeholder="Contract Number"
                v-model="form.contractNo"
              ></b-form-input>
            </div>

            <div class="col-md-6 py-2 col-sm-12">
              <label for="input-large">Status</label>

              <b-form-select
                v-model="form.status"
                :options="options.statuses"
                size="lg"
                disabled
              ></b-form-select>
            </div>
          </div>

          <div class="d-flex justify-content-end mt-3">
            <b-button
              variant="success"
              class="mr-2"
              @click="save"
              :disabled="loading"
            >
              <i class="flaticon2-check-mark"></i> Save
            </b-button>

            <b-button
              variant="secondary"
              class="mr-2"
              @click="draft"
              :disabled="loading"
            >
              <i class="flaticon2-contract"></i> Draft
            </b-button>

            <b-button
              variant="secondary"
              @click="downloadFile"
              :disabled="loading"
            >
              <i class="flaticon2-printer"></i> Print
            </b-button>

            <b-button
              class="ml-2"
              variant="secondary"
              @click="downloadFileDraft"
              :disabled="loading"
            >
              <i class="flaticon2-download"></i> Download Draft
            </b-button>
          </div>
        </div>
      </div>

      <div class="card card-custom p-5 gutter-b" v-if="!loading">
        <div class="card-body p-0">
          <b-tabs content-class="mt-3">
            <b-tab title="Info DMP">
              <dmp-info
                :dmp-info="currentForm.design_management_plan"
                @setDmpInfo="setCurrentForm"
              ></dmp-info>
            </b-tab>

            <b-tab title="Project Overview">
              <description
                :descriptions="currentForm.scope_of_contract"
                @setDescriptions="setCurrentForm"
              ></description>
            </b-tab>

            <b-tab title="Design Reference">
              <design-reference
                :design-references="currentForm.design_reference"
                @setDesignReferences="setCurrentForm"
              ></design-reference>
            </b-tab>

            <b-tab title="Abbreviations">
              <abbrevation
                :acronyms="currentForm.acronym"
                @setAbbreviations="setCurrentForm"
              ></abbrevation>
            </b-tab>

            <b-tab title="Scope Of Design">
              <design-scope
                :design-scopes="currentForm.scope_of_design"
                @setDesignScopes="setCurrentForm"
              ></design-scope>
            </b-tab>
          </b-tabs>
        </div>
      </div>

      <div
        class="card card-custom d-flex justify-content-center p-5"
        v-if="loading"
      >
        <div class="spinner-border text-primary mx-auto" role="status">
          <span class="visually-hidden"></span>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import Description from "./edit-components/Description.vue";
import Abbrevation from "./edit-components/Abbrevation.vue";
import DesignReference from "./edit-components/DesignReference.vue";
import DmpInfo from "./edit-components/DmpInfo.vue";
import DesignScope from "./edit-components/DesignScope.vue";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import {
  GET_ONE_DESIGN_MANAGEMENT_PLAN_BY_CODE,
  UPDATE_DESIGN_MANAGEMENT_PLAN,
  UPDATE_DRAFT_DESIGN_MANAGEMENT_PLAN
} from "../../../../core/services/store/design-management-plan.module";
import { mapState } from "vuex";
import ApiService from "../../../../core/services/api.service";
import { showToast } from "../../../../core/helper/toast.helper";

export default {
  name: "EditDmp",
  components: {
    Description,
    DmpInfo,
    Abbrevation,
    DesignReference,
    DesignScope
  },
  data() {
    return {
      form: {
        code: this.$route.params?.code,
        contractNo: null,
        dmpRevision: null,
        name: null,
        nickname: null,
        owner: null,
        status: null
      },
      currentForm: {
        project: {
          code: this.$route.params?.code
        },
        design_management_plan: {
          id: null,
          number: null,
          revision: null,
          revision_date: null,
          vp_name: null,
          evp_name: null
        },
        acronym: [],
        scope_of_contract: [],
        scope_of_design: [],
        design_reference: []
      },
      options: {
        statuses: [
          { value: null, text: "Choose Status" },
          { value: "open", text: "Open" },
          { value: "close", text: "Close" }
        ]
      },
      data: {
        descriptions: [
          {
            id: 1,
            value: "Ini contoh uraian"
          }
        ],
        dmp_info: {
          dmp_number: null,
          revision: null,
          evp_name: null,
          vp_name: null,
          revision_date: null,
          document: null
        },
        abbreviations: [
          {
            id: 1,
            abbreviation: "ER",
            description: "Equipment Room"
          }
        ],
        design_references: [
          {
            id: 1,
            description: "Contoh Uraian",
            type: 1
          }
        ],
        design_scopes: [
          {
            id: 1,
            scope_id: 1,
            subsystem_id: 1,
            description: "ini deskripsi"
          }
        ]
      }
    };
  },
  computed: {
    ...mapState({
      errors: state => state.designManagementPlan.errors,
      loading: state => state.designManagementPlan.loading,
      currentDesignManagementPlan: state =>
        state.designManagementPlan.currentDesignManagementPlan?.data
    }),

    currentData() {
      return this.currentDesignManagementPlan;
    }
  },
  watch: {
    currentData(data) {
      if (data) {
        this.form = {
          code: data?.project?.code,
          contractNo: data?.project?.contract_no,
          dmpRevision: data?.dmp_revision,
          name: data?.project?.name,
          nickname: data?.project?.nickname,
          owner: data?.project?.owner,
          status: data?.project?.status
        };

        Object.assign(this.currentForm, {
          ...data,
          project: data?.project
            ? data.project
            : {
                code: null
              },
          design_management_plan: data?.design_management_plan
            ? data.design_management_plan
            : {
                id: null,
                number: null,
                revision: null,
                revision_date: null,
                vp_name: null,
                evp_name: null
              }
        });
      }
    }
  },
  methods: {
    save() {
      if (!this.validateDesignReference(this.currentForm?.design_reference)) {
        showToast(
          "Failed",
          "Description must be filled on design reference",
          "danger"
        );
      } else {
        this.$store
          .dispatch(UPDATE_DESIGN_MANAGEMENT_PLAN, {
            ...this.currentForm,
            project: {
              code: this.form.code
            }
          })
          .then(() => {
            this.toIndexPage();
          });
      }
    },

    draft() {
      if (!this.validateDesignReference(this.currentForm?.design_reference)) {
        showToast(
          "Failed",
          "All field must be filled on Design Reference",
          "danger"
        );
      } else if (
        !this.validateScopeOfContract(this.currentForm?.scope_of_contract)
      ) {
        showToast(
          "Failed",
          "Description must be filled on Description of Contract Work Scope",
          "danger"
        );
      } else if (!this.validateAcronym(this.currentForm?.acronym)) {
        showToast(
          "Failed",
          "All field must be filled on abbreviation",
          "danger"
        );
      } else if (!this.validateDesignScope(this.currentForm?.scope_of_design)) {
        showToast(
          "Failed",
          "All field must be filled on Scope Of design",
          "danger"
        );
      } else {
        this.$store
          .dispatch(UPDATE_DRAFT_DESIGN_MANAGEMENT_PLAN, {
            ...this.currentForm,
            project: {
              code: this.form.code ?? this.$route.params?.code
            }
          })
          .then(() => {
            this.toIndexPage();
          });
      }
    },

    validateDesignReference(designReference) {
      let validate = true;

      designReference?.forEach(reference => {
        if (!reference.description) {
          validate = false;
        }

        if (!reference.design_reference_type?.code) {
          validate = false;
        }
      });

      return validate;
    },

    validateScopeOfContract(designReference) {
      let validate = true;

      designReference?.forEach(reference => {
        if (!reference.description) {
          validate = false;
        }
      });

      return validate;
    },

    validateAcronym(designReference) {
      let validate = true;

      designReference?.forEach(reference => {
        if (!reference.description) {
          validate = false;
        }

        if (!reference.acronym) {
          validate = false;
        }
      });

      return validate;
    },

    validateDesignScope(designReference) {
      let validate = true;

      designReference?.forEach(reference => {
        if (!reference.scope_of_work?.code) {
          validate = false;
        }

        if (!reference.subsystem_of_work?.code) {
          validate = false;
        }
      });

      return validate;
    },

    setCurrentForm(data) {
      Object.assign(this.currentForm, data);
    },

    toIndexPage() {
      this.$router.push({
        name: "project-design-management-plan"
      });
    },

    toDownloadPage() {
      window.open(
        this.currentDesignManagementPlan?.design_management_plan?.file,
        "_blank"
      );
    },

    toEditPage(data) {
      this.$router.push({
        name: "edit-detail-project",
        params: {
          code: data.code
        }
      });
    },

    setDescriptions(descriptions) {
      this.data.descriptions = descriptions;
    },

    setPics(pics) {
      this.data.pics = pics;
    },

    setCompanies(companies) {
      this.data.companies = companies;
    },

    setDmpInfo(dmpInfo) {
      this.data.dmp_info = dmpInfo;
    },

    setAbbreviations(abbreviations) {
      this.data.abbreviations = abbreviations;
    },

    setDesignReferences(designReferences) {
      this.data.design_references = designReferences;
    },

    setDesignScopes(designScopes) {
      this.data.design_scopes = designScopes;
    },

    downloadFile() {
      ApiService.setHeader();

      ApiService.postDownload("ftp/download", {
        file_url: this.currentForm?.design_management_plan?.file
      })
        .then(response => {
          console.log(response.data);
          const fileURL = window.URL.createObjectURL(
            new Blob([response?.data])
          );

          const fileLink = document.createElement("a");

          fileLink.href = fileURL;

          // const urlSplitted = this.currentForm?.design_management_plan?.file?.split('/');

          // const length = urlSplitted?.length;

          fileLink.setAttribute(
            "download",
            `FRM-EOP-01-02 ${this.currentForm.project.nickname} ${this.currentForm.design_management_plan.revision} .pdf`
          );

          document.body.appendChild(fileLink);

          fileLink.click();
        })
        .catch(() => {
          showToast("Error", "Failed download", "danger");
        });
    },

    downloadFileDraft() {
      ApiService.setHeader();

      ApiService.postDownload("ftp/download", {
        file_url: this.currentForm?.design_management_plan?.file_draft
      })
        .then(response => {
          console.log(response.data);
          const fileURL = window.URL.createObjectURL(
            new Blob([response?.data])
          );

          const fileLink = document.createElement("a");

          fileLink.href = fileURL;

          // const urlSplitted = this.currentForm?.design_management_plan?.file?.split('/');

          // const length = urlSplitted?.length;

          fileLink.setAttribute(
            "download",
            `Draft FRM-EOP-01-02 ${this.currentForm.project.nickname} ${this.currentForm.design_management_plan.revision} .pdf`
          );

          document.body.appendChild(fileLink);

          fileLink.click();
        })
        .catch(() => {
          showToast("Error", "Failed download", "danger");
        });
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Edit Design Management Plan" }
    ]);

    this.$store.dispatch(GET_ONE_DESIGN_MANAGEMENT_PLAN_BY_CODE, {
      params: {
        code: this.$route.params?.code
      }
    });
  }
};
</script>

<style></style>
