<template>
  <div>
    <div class="d-flex my-3 justify-content-start">
      <button class="btn btn-success btn-md" @click="add()">
        <i class="flaticon-add"></i> Add Abbrevation
      </button>
    </div>

    <div class="table-responsive mt-5">
      <table
        class="table table-head-custom table-vertical-center table-head-bg table-borderless"
      >
        <thead>
          <tr class="text-left">
            <th class="pl-7" style="min-width: 250px">
              <span class="text-dark-75">Abbrevation</span>
            </th>

            <th class="pl-7" style="min-width: 250px">
              <span class="text-dark-75">Description</span>
            </th>

            <th style="min-width: 100px">Action</th>
          </tr>
        </thead>

        <tbody>
          <template v-for="(item, i) in acronyms">
            <tr v-bind:key="i">
              <td class="pl-0 ">
                <template v-if="currentIndex !== i">
                  <span
                    class="ml-5 text-dark-75 font-weight-bolder d-block font-size-lg"
                  >
                    {{ item.acronym }}
                  </span>
                </template>

                <template v-if="currentIndex === i">
                  <b-form-input
                    id="input-large"
                    size="lg"
                    v-model="item.acronym"
                  ></b-form-input>
                </template>
              </td>

              <td class="pl-0 ">
                <template v-if="currentIndex !== i">
                  <span
                    class="ml-5 text-dark-75 font-weight-bolder d-block font-size-lg"
                  >
                    {{ item.description }}
                  </span>
                </template>

                <template v-if="currentIndex === i">
                  <b-form-input
                    id="input-large"
                    size="lg"
                    v-model="item.description"
                  ></b-form-input>
                </template>
              </td>

              <td class="pl-0 ">
                <a
                  class="btn btn-light-success font-weight-bolder font-size-sm"
                  @click="currentIndex = i"
                  v-if="currentIndex !== i"
                >
                  <i class="flaticon-edit"></i>
                </a>

                <a
                  class="btn btn-light-success font-weight-bolder font-size-sm"
                  @click="currentIndex = null"
                  v-if="currentIndex === i"
                >
                  <i class="flaticon2-cross"></i>
                </a>

                <a
                  class="btn btn-light-danger font-weight-bolder font-size-sm ml-2"
                  @click="remove(i)"
                >
                  <i class="flaticon2-trash"></i>
                </a>
              </td>
            </tr>
          </template>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import {deleteModal} from "../../../../../core/helper/toast.helper";

export default {
  name: "Abbreviation",
  props: {
    acronyms: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      acronym: {
        id: null,
        value: null
      },
      currentIndex: null
    };
  },
  methods: {
    remove(index) {
      const deleteFunction = () => {
        const acronyms = this.acronyms;

        acronyms.splice(index, 1);

        this.$emit("setAbbreviations", { acronym: acronyms });
      }

      deleteModal(deleteFunction);
    },

    add() {
      const acronyms = this.acronyms;

      acronyms.push({
        id: null,
        acronym: null
      });

      this.$emit("setAbbreviations", { acronym: acronyms });
    },

    update(index, acronym) {
      const acronyms = this.acronyms;

      acronyms[index] = acronym;

      this.$emit("setAbbreviations", { acronym: acronyms });
    }
  }
};
</script>
