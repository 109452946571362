<template>
  <div>
    <div class="d-flex my-3 justify-content-start">
      <button class="btn btn-success btn-md" @click="add()">
        <i class="flaticon-add"></i> Add Scope Of Design
      </button>
    </div>

    <div class="table-responsive mt-5">
      <table
        class="table table-head-custom table-vertical-center table-head-bg table-borderless"
      >
        <thead>
          <tr class="text-left">
            <th class="pl-7" style="min-width: 250px">
              <span class="text-dark-75">Scope Of Design</span>
            </th>
            <th style="min-width: 100px">Subsystem Of Work</th>
            <th style="min-width: 100px">Description</th>
            <th style="min-width: 100px">Action</th>
          </tr>
        </thead>

        <tbody>
          <template v-for="(designScope, i) in designScopes">
            <tr v-bind:key="i">
              <td class="pl-0 ">
                <template v-if="currentIndex !== i">
                  <span
                    class="ml-5 text-dark-75 font-weight-bolder d-block font-size-lg"
                  >
                    {{ designScope.scope_of_work ? designScope.scope_of_work.name : '-' }}
                  </span>
                </template>

                <template v-if="currentIndex === i">
                  <b-form-select
                    v-model="designScope.scope_of_work.code"
                    :options="formattedScopeOfDesignOptions"
                    size="lg"
                    @change="setScopeWork($event, i)"
                  ></b-form-select>
                </template>
              </td>

              <td class="pl-0 ">
                <template v-if="currentIndex !== i">
                  <span
                    class="ml-5 text-dark-75 font-weight-bolder d-block font-size-lg"
                  >
                    {{ designScope.subsystem_of_work ? designScope.subsystem_of_work.name : '-' }}
                  </span>
                </template>

                <template v-if="currentIndex === i">
                  <b-form-select
                    v-model="designScope.subsystem_of_work.code"
                    :options="formattedSubSystemOfWorkOptions"
                    size="lg"
                    @change="setSubsystemWork($event, i)"
                  ></b-form-select>
                </template>
              </td>

              <td class="pl-0 ">
                <template v-if="currentIndex !== i">
                  <span
                    class="ml-5 text-dark-75 font-weight-bolder d-block font-size-lg"
                  >
                    {{ designScope.description ? designScope.description : '-' }}
                  </span>
                </template>

                <template v-if="currentIndex === i">
                  <b-form-input
                    id="input-large"
                    size="lg"
                    v-model="designScope.description"
                  ></b-form-input>
                </template>
              </td>

              <td class="pl-0 ">
                <a
                  class="btn btn-light-success font-weight-bolder font-size-sm"
                  @click="currentIndex = i"
                  v-if="currentIndex !== i"
                >
                  <i class="flaticon-edit"></i>
                </a>

                <a
                  class="btn btn-light-success font-weight-bolder font-size-sm"
                  @click="currentIndex = null"
                  v-if="currentIndex === i"
                >
                  <i class="flaticon2-cross"></i>
                </a>

                <a
                  class="btn btn-light-danger font-weight-bolder font-size-sm ml-2"
                  @click="remove(i)"
                >
                  <i class="flaticon2-trash"></i>
                </a>
              </td>
            </tr>
          </template>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { arrayMoreThanOne } from "../../../../../core/helper/array-validation.helper";
import { GET_SCOPE_OF_DESIGN } from "../../../../../core/services/store/scope-of-design.module";
import { mapState } from "vuex";
import { GET_SUBSYSTEM_OF_WORK } from "../../../../../core/services/store/subsystem-of-work.module";
import {deleteModal} from "../../../../../core/helper/toast.helper";

export default {
  name: "DesignScope",
  props: {
    designScopes: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      designScope: {
        id: null,
        station: null
      },
      currentIndex: null,
      options: {
        scopes: [
          {
            text: "Desain Sistem",
            value: 1
          },
          {
            text: "Desain Produk",
            value: 2
          },
          {
            text: "Desain Kontruksi",
            value: 3
          }
        ],
        subsystems: [
          {
            text: "Sinyal",
            value: 1
          },
          {
            text: "Power",
            value: 2
          },
          {
            text: "Telkom",
            value: 3
          }
        ]
      }
    };
  },
  computed: {
    ...mapState({
      scopeOfDesign: state => state.scopeOfDesign.scopeOfDesigns?.data,
      subSystemOfWork: state => state.subSystemOfWork.subSystemOfWork?.data
    }),

    formattedScopeOfDesignOptions() {
      let scopeOfDesign = [];

      if (arrayMoreThanOne(this.scopeOfDesign)) {
        scopeOfDesign = this.scopeOfDesign.map(scope => {
          return {
            value: scope.code,
            text: scope.name
          };
        });
      }

      return [{ value: null, text: "Choose Scope Of Design" }, ...scopeOfDesign];
    },

    formattedSubSystemOfWorkOptions() {
      let subSystemOfWork = [];

      if (arrayMoreThanOne(this.subSystemOfWork)) {
        subSystemOfWork = this.subSystemOfWork.map(scope => {
          return {
            value: scope.code,
            text: scope.name
          };
        });
      }

      return [{ value: null, text: "Choose Subsystem Of Work" }, ...subSystemOfWork];
    }
  },
  methods: {
    setSubsystemWork(value, index) {
      const currentOption = this.formattedSubSystemOfWorkOptions.find(
        data => data.value === this.designScopes[index].subsystem_of_work.code
      );

      this.designScopes[index].subsystem_of_work.name = currentOption?.text;

      this.$emit("setDesignReferences", {
        scope_of_design: this.designScopes
      });
    },

    setScopeWork(value, index) {
      const currentOption = this.formattedScopeOfDesignOptions.find(
        data => data.value === this.designScopes[index].scope_of_work.code
      );

      this.designScopes[index].scope_of_work.name = currentOption?.text;

      this.$emit("setDesignReferences", {
        scope_of_design: this.designScopes
      });
    },

    displayScopeName(value) {
      return this.options.scopes.find(design => design.value === value)?.text;
    },

    displaySubsystemName(value) {
      return this.options.subsystems.find(design => design.value === value)
        ?.text;
    },

    remove(index) {
      const deleteFunction = () => {
        const designScopes = this.designScopes;

        designScopes.splice(index, 1);

        this.$emit("setDesignScopes", { scope_of_design: designScopes });
      }

      deleteModal(deleteFunction);
    },

    add() {
      const designScopes = this.designScopes;

      designScopes.push({
        id: null,
        subsystem_of_work: {
          code: null,
          name: null
        },
        scope_of_work: {
          code: null,
          name: null
        },
        description: ""
      });

      this.$emit("setDesignScopes", { scope_of_design: designScopes });
    },

    update(index, designScope) {
      const designScopes = this.designScopes;

      designScopes[index] = designScope;

      this.$emit("setDesignScopes", { scope_of_design: designScopes });
    },

    getScopeDesign() {
      if (!arrayMoreThanOne(this.scopeOfDesign)) {
        this.$store.dispatch(GET_SCOPE_OF_DESIGN, {});
      }
    },

    getSubSystemOfWork() {
      if (!arrayMoreThanOne(this.subSystemOfWork)) {
        this.$store.dispatch(GET_SUBSYSTEM_OF_WORK, {});
      }
    }
  },
  mounted() {
    this.getScopeDesign();

    this.getSubSystemOfWork();
  }
};
</script>
